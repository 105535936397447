/* src/styles.css */
@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body {
  height: 100vh;
  min-height: 586px;
  /* width: 100vw; */
  background: #101010;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}

.bg {
  background-image: url("../public/images/bg.jpg") !important;
}

.nft-footer-bg-gradient {
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 0.56) 30%,
    #fff 51.46%
  );
}
.nft-footer-bg-gradient-dark {
  background: linear-gradient(
    180deg,
    rgba(16, 16, 16, 0) 0%,
    rgba(16, 16, 16, 0.56) 30%,
    #101010 51.46%
  );
}

/* Standard */
::-webkit-scrollbar {
  width: 6px;
}

/* WebKit (older versions) */
::-webkit-scrollbar {
  width: 6px;
}

/* Track */
::-webkit-scrollbar-track {
  background: none;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #404040;
  border-radius: 6px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
  border-radius: 6px;
}
@keyframes moveToLeft {
  from {
    transform: translateX(0vw); /* Start off-screen to the right */
  }
  to {
    transform: translateX(
      -80%
    ); /* Move to the left until it's completely off-screen */
  }
}
.moving-div {
  animation: moveToLeft 100s linear infinite; /* Adjust time as needed */
  position: absolute; /* Or fixed, depending on your layout needs */
  top: 0; /* Adjust based on where you want your div to start */
  left: 0;
  /* Ensure your div has a width and height set */
}
